import React, { useEffect } from "react";
import { graphql } from "gatsby";
import { Router } from "@reach/router";

import { useMyContext } from "../context/Context";
import Layout from "../components/0nav&footer/NavFooterLayout";
import AboutTheGuideBI from "../components/3aboutEtc/AboutTheGuideBI";
import AboutCreditsBI from "../components/3aboutEtc/AboutCreditsBI";
import AboutMediaBI from "../content/AboutMediaBI";

const About = props => {
  const { location, data } = props;
  const { setWinWidth, setWinHeight, fixedBackdrop, queryData, log, log2 } = useMyContext();
  queryData.current = data;
  const path = location.pathname;

  0 && console.log(log, log2);
  log && console.log("");
  log && console.log("about.js runs. path=", path);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setWinWidth(window.innerWidth);
      setWinHeight(window.innerHeight);
    }
  }, [setWinWidth, setWinHeight]);


  return (
    <div className={`stdMargins  baseFont  flex flex-col  ${fixedBackdrop ? "fixed" : ""}`}>
      {/* Note: for the following routing to work you need the prefix option set up for
            gatsby-plugin-create-client-paths in gatsby-config.js */}
      <Router basepath="/about">
        <AboutTheGuideBI path="/" />
        <AboutMediaBI path="/media" />
        <AboutCreditsBI path="/credits" />
      </Router>
    </div>
  );
};

export const query = graphql`
  query picsQuery {
    homepagePics: allFile(filter: { sourceInstanceName: { eq: "homeAndExplore" }, extension: { eq: "jpg" } }) {
      edges {
        node {
          dir
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(formats: JPG, placeholder: BLURRED)
          }
        }
      }
    }
    collabLogos: allFile(filter: { sourceInstanceName: { eq: "collabLogos" } }) {
      edges {
        node {
          dir
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(formats: AUTO, quality: 90, placeholder: BLURRED, layout: CONSTRAINED, height: 120)
          }
          publicURL
        }
      }
    }
    webinarShots: allFile(filter: { sourceInstanceName: { eq: "webinarShots" } }) {
      edges {
        node {
          dir
          relativePath
          sourceInstanceName
          childImageSharp {
            gatsbyImageData(formats: AUTO, placeholder: BLURRED, quality: 90, layout: CONSTRAINED, height: 300)
          }
          publicURL
        }
      }
    }
  }
`;

About.Layout = Layout;

export default About;
